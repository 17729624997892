import React from 'react';

interface MenuItemProps {
    label: string,
}

export class MenuItem extends React.Component <MenuItemProps, {}> {

    constructor(props: MenuItemProps) {
        super(props);
    }
    
    render() {        
        return(
            <React.Fragment>
            {this.props.label}
            </React.Fragment>
        );
    }
}