import React from 'react';

export interface CardProps {
    name: string,
    image: string,
    link: string,
}

export class Card extends React.Component <CardProps, {}> {
    render() {
        return <div className="card" onClick={() => {window.open(`${this.props.link}`);}}>
            <header className="card-header">
                <h2>{this.props.name}</h2>
            </header>
            <div>
                <img src={require(`${this.props.image}`)}></img> 
            </div>
        </div>
    }
}

