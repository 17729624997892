import React from 'react';
import {MenuItem} from './MenuItem'
import './components-styles/menu.css';

interface MenuProps {
    items: string[]
}

export class Menu extends React.Component <MenuProps, {}> {

    constructor(props: MenuProps) {
        super(props);
    }
    
    render() {


        return(
            <div id='top'>
                <ul>
                    {
                        this.props.items.map((item) => {
                            return <li> <MenuItem label={item} /> </li>
                        })
                    }
                </ul>
            </div>
        );
      }
    }