import React from 'react';
import './components-styles/emailLinkStyle.css';

interface Props {
}

export const EmailLink: React.FC<Props> = () => {
  return (
    <div id="emailLink">
        <a href="mailto:satoshi.nakamura9@gmail.com"> Contact Me! </a>
        <br />
    </div>
  );
};
