import React from 'react';

import {Menu} from './Menu';
import {MenuItem} from './MenuItem';


const MenuItemList = [
    'Profile',
    'Contents'
]

export const MenuFactory = () => (
    <Menu 
        items={MenuItemList}
    />
)

