import React from 'react';
import './components-styles/profile.css'
import { description } from '../constansts/ProfileDescription';
import {Download} from './ResumeDLBtn';

interface ProfileProps {
    profileImage: string,
}

interface ProfileState {
    isDarkMode: boolean;
}

let isDarkMode = true;
let setIsDarkMode = () => {isDarkMode = !isDarkMode;};

const resume_path = 'public/Satoshi_Nakamura_Resume.pdf'

export class Profile extends React.Component <ProfileProps, ProfileState> {

    constructor(props: ProfileProps) {
        super(props);
        this.state = {
            isDarkMode: true,
        }
    }

    toggleDarkMode() {
        this.setState({
            isDarkMode: !(this.state.isDarkMode)
        })
    }
    
    render() {
        return(
            <React.Fragment>
            <br />

            <div className="profile">
                <img 
                    src={require(`${this.props.profileImage}`)}>
                </img>
                <div className="description">
                    <h1>Satoshi Nakamura</h1> 
                    <p>
                        {  description.split('\n').map((value, index) => {
                            return <span key={index}>
                                {value}
                                <br />
                            </span>
                            }
                            )
                        }
                    </p>
                    <Download />
                </div>
            </div>
            </React.Fragment>
        );
    }
}