import React from 'react';
import './components-styles/card.css';
import {Card, CardProps} from './Card';

const cardList: CardProps[] = [
    {
        name:'Github',
        image: './external-files/github_logo.png',
        link: 'https://github.com/satoshidg',
    }, 
    {
        name: 'LinkedIn',
        image: './external-files/linkedin_logo.png',
        link: 'https://www.linkedin.com/in/satoshi-nakamura/'
    }, 
    {
        name: 'Twitter',
        image: './external-files/twitter_logo.png',
        link: 'https://twitter.com/_satoshidg'
    },
    {
        name: 'Youtube (Not ready yet)',
        image: './external-files/youtube_logo.png',
        link: 'https://www.youtube.com/channel/UCrmXiXW_KiC9L6LXixeuCUg/featured?view_as=subscriber'
    },
    {
        name: 'Facebook',
        image: './external-files/facebook_logo.png',
        link: 'https://www.facebook.com/satoshidg'
    },
    {
        name: 'Instagram',
        image: './external-files/instagram_logo.png',
        // image: './external-files/instagram_logo.psd',
        link: 'https://www.instagram.com/satoshidg/'
    },
]


export class CardsContainer extends React.Component<{}, {}> {
    render() {
        return <section className="card-list">
            <div className="card-grid-container">
                {
                    cardList.map(card => {
                        return <Card {...card}/>
                    })
                }
            </div>
        </section>
    }
}