import React from 'react';
import logo from './logo.svg';
import './normalize.css';
import './App.css';
import {Menu} from './components/Menu';
import {MenuFactory} from './components/MenuFactory';
import {CardsContainer} from './components/CardsContainer';
import {Profile} from './components/Profile';
import {ThemeProvider} from 'styled-components';
import {EmailLink} from './components/EmailLink';

function App() {
  return (
      <div className="App">
        <header className="App-header">
          {/* <MenuFactory /> */}
        </header>
        <main className="App-body">
          <Profile profileImage='./external-files/profile_rl.jpg'/>
          <CardsContainer />
          <EmailLink />
        </main>
      </div>
  );
}

export default App;
