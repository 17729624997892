import React, { Component } from 'react';
import './components-styles/resume-btn.css'

export class Download extends Component {

  render() {
    return (
        // <a href={require('./external-files/Satoshi_Nakamura_Resume.pdf')}  target = "_blank" id="dl-btn">View Resume Here</a>
        <a href={process.env.PUBLIC_URL + '/icon.png'}   target = "_blank" id="dl-btn">View Resume Here</a>
        // <link rel="icon" href="" />

    );
  }
}
